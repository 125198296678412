import { filter, findIndex, flow, get, includes, isEmpty, map, some } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import { static as Immutable } from 'seamless-immutable';

import extractPerkUrl from '../extract-perk-url';
import isValidPerkNavItem from '../is-valid-perk-nav-item';

const MENU_ITEM_NAMES_WITH_NEW_FLAG = ['National Pickleball League'];

// @ts-expect-error - Automatic, Please fix when editing this file
const combinePerksItems = (menuItems, perks, perksMenuItemIndex) => [
  ...flow(
    filter(isValidPerkNavItem),
    map((perk) => ({
      isNew: includes(perk.name, MENU_ITEM_NAMES_WITH_NEW_FLAG),
      linkTo: extractPerkUrl(perk.item),
      text: perk.name,
    })),
  )(perks),
  ...menuItems[perksMenuItemIndex].subItems,
];

// @ts-expect-error - Automatic, Please fix when editing this file
const combineMenuItemsWithCMSData = (menuItems, perks) => {
  if (isEmpty(perks)) {
    return menuItems;
  }

  // Check if root level menu has "perks" first
  const perksMenuItemIndex = findIndex({ id: 'perks' }, menuItems);
  if (perksMenuItemIndex >= 0) {
    return Immutable.setIn(
      menuItems,
      [perksMenuItemIndex, 'subItems'],
      combinePerksItems(menuItems, perks, perksMenuItemIndex),
    );
  }

  // If not, start looking for sub menu level items for "perks"
  // @ts-expect-error - Automatic, Please fix when editing this file
  const perksParentMenuItemIndex = findIndex((menuItem) => some({ id: 'perks' }, menuItem.subItemGroups), menuItems);
  if (perksParentMenuItemIndex < 0) {
    return menuItems;
  }

  const subItemGroups = get('subItemGroups', menuItems[perksParentMenuItemIndex]);
  const perksSubmenuItemIndex = findIndex({ id: 'perks' }, subItemGroups);
  if (perksSubmenuItemIndex < 0) {
    return menuItems;
  }

  return Immutable.setIn(
    menuItems,
    [perksParentMenuItemIndex, 'subItemGroups', perksSubmenuItemIndex, 'subItems'],
    combinePerksItems(subItemGroups, perks, perksSubmenuItemIndex),
  );
};

export default combineMenuItemsWithCMSData;
